export const TitleStyle = {
  fontSize: 20,
  fontWeight: 'normal',
  fontFamily: 'Roboto',
  color: 'black',
};

export const TextStyle = {
  fontSize: 18,
  fontWeight: 'normal',
  fontFamily: 'Roboto',
  color: 'gray',
};
