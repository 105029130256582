import { LANG_TYPE } from '../blogs/blogs';

//format date in Month date, year
export const formatDate = (dateStr: string) => {
  // Split the input string into components
  const [month, day, year] = dateStr.split('/');

  // Create a new Date object
  const date = new Date(`${month}/${day}/${year}`);

  // Format the date
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  //@ts-ignore
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
};

export const blogIcon = (lan: LANG_TYPE) => {
  switch (lan) {
    case LANG_TYPE.CPP:
      return require('../assets/langs/cpp.png');
    case LANG_TYPE.JAVA:
      return require('../assets/langs/java.png');
    case LANG_TYPE.JAVASCRIPT:
      return require('../assets/langs/javascript.png');
    case LANG_TYPE.PYTHON:
      return require('../assets/langs/python.png');
    case LANG_TYPE.REACT:
      return require('../assets/langs/react.png');
    case LANG_TYPE.SPRINT_BOOT:
      return require('../assets/langs/springboot.png');
    default:
      return require('../assets/langs/unknown.png');
  }
};
