import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../App';
import { BLOG } from '../../blogs/blogs';

export const BLOG_CARD_WIDTH = 500;
export const BLOG_CARD_HEIGHT = 350;
interface BlogCardProps {
  blog: BLOG;
}
const BlogCard = ({ blog }: BlogCardProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        w={BLOG_CARD_WIDTH}
        h={BLOG_CARD_HEIGHT}
        boxShadow="sm"
        rounded="xl"
        overflow="hidden"
        bg="gray.50"
        as="button"
        onClick={() => navigate(`${ROUTE_PATHS.BLOGS}/${blog.param}`, { state: { id: blog.id } })}
      >
        <Box sx={{ width: '100%', height: '70%' }}>
          <Image sx={{ width: '100%', height: '100%' }} objectFit="cover" src={blog.imagePath} alt="blog pic" />
        </Box>
        <VStack
          sx={{ width: '100%', height: '30%', paddingRight: 5, paddingLeft: 5, alignItems: 'baseline', justifyContent: 'space-around' }}
        >
          <Text fontSize="20px" fontFamily="roboto" fontWeight="500" noOfLines={1} color="gray.700">
            {blog.title}
          </Text>
          <HStack width="100%" display="flex" align="center" justifyContent="space-between">
            <Text fontSize="10px" fontFamily="roboto" color="gray.450">
              Last updated {blog.lastUpdate}
            </Text>
            <Box
              boxShadow="sm"
              sx={{ bg: '#ffffff', borderWidth: 0.5, borderColor: '#ED7D31', paddingLeft: 3, borderRadius: 10, paddingRight: 3 }}
            >
              <Text fontSize="10px" fontFamily="roboto" color="gray.450">
                {blog.type}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

export default BlogCard;
