import { Box, Button, Flex, HStack, Image, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../App';

const Links = ['Home', 'About', 'Contacts'];

const AppHeader = () => {
  const navigate = useNavigate();

  const onClick = (title: string) => {
    title = title.toLocaleLowerCase();
    if (title === ROUTE_PATHS.HOME) {
      return navigate('/');
    } else if (title === ROUTE_PATHS.ABOUT) {
      return navigate(ROUTE_PATHS.ABOUT);
    }
    return navigate(ROUTE_PATHS.CONTACTS);
  };
  return (
    <>
      <Box width="100%" bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'center'}>
            <Box sx={{ width: '25px', height: '25%' }} as="button" onClick={() => navigate('/')}>
              <Image objectFit="cover" src={require('../assets/coding.png')} alt="Dan Abramov" />
            </Box>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              {Links.map((link, index) => (
                <Button
                  key={index}
                  color="#333333"
                  fontSize="18px"
                  fontFamily="roboto"
                  variant="unstyled"
                  h="auto"
                  marginLeft="20px"
                  marginRight="20px"
                  _hover={{
                    backgroundColor: '#e6e6e6',
                  }}
                  // _active={{
                  //   backgroundColor: 'red',
                  // }}
                  onClick={() => onClick(link)}
                >
                  {link}
                </Button>
              ))}
            </HStack>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};
export default AppHeader;
