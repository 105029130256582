export enum LANG_TYPE {
  REACT = 'React',
  TYPESCRIPT = 'Typescript',
  JAVASCRIPT = 'Javascript',
  JAVA = 'Java',
  CPP = 'C++',
  PYTHON = 'Python',
  SPRINT_BOOT = 'Spring Boot',
}
export interface BLOG {
  id: number;
  title: string;
  param: string;
  type: LANG_TYPE;
  imagePath: string;
  routePath: string;
  lastUpdate: string;
}

export const BLOGS: BLOG[] = [
  {
    id: 0,
    title: 'How to run Java on a terminal',
    param: 'java-run-on-terminal',
    type: LANG_TYPE.JAVA,
    imagePath: require('../assets/blogs/java-terminal.png'),
    routePath: 'java/terminal',
    lastUpdate: '03/20/2024',
  },
];
