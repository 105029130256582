import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { FULL_HEIGHT } from '../../global-constants/sizes';
import { formatDate } from '../../utils/utils';

const headerStyle = {
  color: '#666666',
  fontSize: '25px',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  marginTop: '30px',
};

const titleStyle = {
  ...headerStyle,
  color: '#333333',
  fontSize: '30px',
};
const textStyle = {
  fontSize: '18px',
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  color: '#999999',
};
function TermsContainer() {
  return (
    <Box w="100%" minH={FULL_HEIGHT} display="flex" alignItems="flex-start" justifyContent="center">
      <VStack w="70%" h="100%" p={20} display="flex" alignItems="flex-start" justifyContent="flex-start">
        <Text style={{ ...titleStyle }}>Terms and Conditions of Use</Text>
        <Text style={{ ...textStyle }}>
          These Terms and Conditions govern your use of the website wediHadgu. By accessing or using the Website, you agree to be bound by
          these Terms and Conditions. If you do not agree with all of these Terms and Conditions, you may not access or use the Website.
        </Text>
        <Text style={{ ...headerStyle }}>Use of Websitet</Text>

        <Text style={{ ...textStyle }}>
          You must be at least 13 years old to use this Website. By using the Website and agreeing to these Terms and Conditions, you
          represent and warrant that you are at least 13 years old.
        </Text>
        <Text style={{ ...textStyle }}>
          You agree to use the Website only for lawful purposes and in accordance with these Terms and Conditions.
        </Text>

        <Text style={{ ...headerStyle }}>Cookies and Analytics</Text>

        <Text style={{ ...textStyle }}>
          The Website may use cookies to enhance your experience. By using the Website, you consent to the use of cookies in accordance with
          the Website's Privacy Policy.
        </Text>
        <Text style={{ ...textStyle }}>
          The Website may also use third-party analytics services such as Google Analytics to collect information about your use of the
          Website. This information may include your IP address, browser type, pages visited, and other usage statistics. The information
          collected by these third-party services will be used to tailor resources to suit your interests and improve the performance of the
          Website.
        </Text>

        <Text style={{ ...headerStyle }}>Intellectual Property</Text>

        <Text style={{ ...textStyle }}>
          All content on the Website, including but not limited to text, graphics, logos, images, and software, is the property of wediHadgu
          or its content suppliers and is protected by copyright and other intellectual property laws.
        </Text>
        <Text style={{ ...textStyle }}>
          You may not reproduce, modify, distribute, or publicly display any content from the Website without the prior written consent of
          wediHadgu.
        </Text>

        <Text style={{ ...headerStyle }}>Disclaimer of Warranties</Text>
        <Text style={{ ...textStyle }}>
          {' '}
          The Website is provided on an "as is" and "as available" basis. wediHadgu makes no representations or warranties of any kind,
          express or implied, regarding the operation or availability of the Website or the accuracy, completeness, or reliability of any
          information provided on the Website.
        </Text>
        <Text style={{ ...textStyle }}>
          To the fullest extent permitted by law, wediHadgu disclaims all warranties, express or implied, including but not limited to
          implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </Text>

        <Text style={{ ...headerStyle }}> Limitation of Liability</Text>
        <Text style={{ ...textStyle }}>
          {' '}
          In no event shall wediHadgu be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in
          any way connected with your use of the Website, whether based on contract, tort, strict liability, or any other legal theory, even
          if wediHadgu has been advised of the possibility of such damages.
        </Text>

        <Text style={{ ...headerStyle }}>Changes to Terms and Conditions</Text>
        <Text style={{ ...textStyle }}>
          WediHadgu reserves the right to modify or revise these Terms and Conditions at any time without prior notice. By continuing to use
          the Website after any such changes, you agree to be bound by the revised Terms and Conditions.
        </Text>

        <Text style={{ ...headerStyle }}>Contact Information</Text>
        <Text style={{ ...textStyle }}>
          If you have any questions or concerns about these Terms and Conditions, please contact us at
          <Link color="#73afee" href="mailto:gomche02@gmail.com">
            {' '}
            gomche02@gmail.com
          </Link>
        </Text>
        <Text style={{ fontSize: 8, fontFamily: 'Roboto', color: 'gray', marginTop: 40 }}>
          This Terms & Conditions was last updated on {formatDate('03/10/2024')}
        </Text>
      </VStack>
    </Box>
  );
}

export default TermsContainer;
