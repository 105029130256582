import { Box, HStack, Select, SimpleGrid } from '@chakra-ui/react';
import { BLOGS } from '../../blogs/blogs';
import BlogCard, { BLOG_CARD_WIDTH } from '../common/BlogCard';

//icons
import { IoIosSearch } from 'react-icons/io';
import { FULL_HEIGHT } from '../../global-constants/sizes';

const HomeContainer = () => {
  return (
    <Box w="100%" minH={FULL_HEIGHT}>
      {/* <SearchBar /> */}
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="8" p="10" textAlign="center" rounded="lg" color="gray.400">
        {BLOGS.map((blog, index) => (
          <BlogCard key={index} blog={blog} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default HomeContainer;

// Search bar will be implemented on the second phase
export const SearchBar = () => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <HStack mt={30}>
      <HStack
        sx={{
          width: BLOG_CARD_WIDTH + 50,
          height: 50,
          borderRadius: 30,
          bg: 'gray.400',

          paddingRight: 5,
          paddingLeft: 5,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IoIosSearch size={25} />
        <IoIosSearch size={25} />
      </HStack>
      <Select placeholder="Languages" sx={{ width: 200, height: 50, borderRadius: 50 }}>
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </Select>
    </HStack>
  );
};
