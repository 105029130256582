import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { FULL_HEIGHT } from '../../global-constants/sizes';
import { formatDate } from '../../utils/utils';

const headerStyle = {
  color: '#666666',
  fontSize: '25px',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  marginTop: '30px',
};

const titleStyle = {
  ...headerStyle,
  color: '#333333',
  fontSize: '30px',
};
const textStyle = {
  fontSize: '18px',
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  color: '#999999',
};
function TermsMobileContainer() {
  return (
    <Box w="100%" minH={FULL_HEIGHT} display="flex" alignItems="flex-start" justifyContent="center">
      <VStack w="70%" h="100%" p={20} display="flex" alignItems="flex-start" justifyContent="flex-start">
        <Text style={{ ...titleStyle }}>Terms and Conditions of Use</Text>
        <Text style={{ ...textStyle }}>
          These Terms and Conditions govern your use of the application. By accessing or using the app, you agree to be bound by these Terms
          and Conditions. If you do not agree with all of these Terms and Conditions, you may not access or use the app.
        </Text>
        <Text style={{ ...headerStyle }}>Use of the Application</Text>

        <Text style={{ ...textStyle }}>
          You must be at least 13 years old to use this application. By using the app and agreeing to these Terms and Conditions, you
          represent and warrant that you are at least 13 years old.
        </Text>
        <Text style={{ ...textStyle }}>
          You agree to use the App only for lawful purposes and in accordance with these Terms and Conditions.
        </Text>

        <Text style={{ ...headerStyle }}>Third-Party Analytics</Text>

        <Text style={{ ...textStyle }}>
          The App may use third-party analytics services such as Google Analytics to collect information about your use of the App. This
          information may include your IP address, pages visited, and other usage statistics. The information collected by these third-party
          services will be used to tailor resources to suit your interests and improve the performance of the App.
        </Text>

        <Text style={{ ...headerStyle }}>Intellectual Property</Text>

        <Text style={{ ...textStyle }}>
          All content on the App, including but not limited to text, graphics, logos, images, and software, is the property of Naina Inc. or
          its content suppliers and is protected by copyright and other intellectual property laws.
        </Text>
        <Text style={{ ...textStyle }}>
          You may not reproduce, modify, distribute, or publicly display any content from the App without the prior written consent of Naina
          Inc.
        </Text>

        <Text style={{ ...headerStyle }}>Disclaimer of Warranties</Text>
        <Text style={{ ...textStyle }}>
          {' '}
          The App is provided on an "as is" and "as available" basis. Naina Inc. makes no representations or warranties of any kind, express
          or implied, regarding the operation or availability of the App or the accuracy, completeness, or reliability of any information
          provided on the App.
        </Text>
        <Text style={{ ...textStyle }}>
          To the fullest extent permitted by law, Naina Inc. disclaims all warranties, express or implied, including but not limited to
          implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </Text>

        <Text style={{ ...headerStyle }}> Limitation of Liability</Text>
        <Text style={{ ...textStyle }}>
          {' '}
          In no event shall Naina Inc. be liable for any direct, indirect, incidental, special, or consequential damages arising out of or
          in any way connected with your use of the App, whether based on contract, tort, strict liability, or any other legal theory, even
          if Naina Inc. has been advised of the possibility of such damages.
        </Text>

        <Text style={{ ...headerStyle }}>Changes to Terms and Conditions</Text>
        <Text style={{ ...textStyle }}>
          Naina Inc. reserves the right to modify or revise these Terms and Conditions at any time without prior notice. By continuing to
          use the App after any such changes, you agree to be bound by the revised Terms and Conditions.
        </Text>

        <Text style={{ ...headerStyle }}>Contact Information</Text>
        <Text style={{ ...textStyle }}>
          If you have any questions or concerns about these Terms and Conditions, please contact us at
          <Link color="#73afee" href="mailto:gomche02@gmail.com">
            {' '}
            gomche02@gmail.com
          </Link>
        </Text>

        <Text style={{ fontSize: 8, fontFamily: 'Roboto', color: 'gray', marginTop: 40 }}>
          This Terms & Conditions was last updated on {formatDate('03/10/2024')}
        </Text>
      </VStack>
    </Box>
  );
}

export default TermsMobileContainer;
