import { HStack, IconButton, Link, Text, VStack } from '@chakra-ui/react';
import { BsGithub, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { ROUTE_PATHS } from '../../App';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const TextStyle = {
  fontSize: 16,
  color: 'gray',
  textDecoration: 'none',
  fontFamily: 'Roboto',
  fontWeight: 500,
};
const FooterContainer = () => {
  const navigate = useNavigate();

  const onClick = (link: string, category: string) => {
    ReactGA.event({
      category: category,
      action: 'Click',
      label: 'platform',
    });

    window.open(link, '_blank');
  };
  return (
    <VStack w="100%" h={200} marginTop={50} alignItems="center" justifyContent="center" display="flex">
      <HStack spacing={10}>
        <Link style={{ ...TextStyle }} href="" onClick={() => navigate(ROUTE_PATHS.ABOUT)}>
          About
        </Link>
        <Link style={{ ...TextStyle }} href="" onClick={() => navigate(ROUTE_PATHS.CONTACTS)}>
          Contacts
        </Link>
        <Link style={{ ...TextStyle }} href="" onClick={() => navigate(ROUTE_PATHS.POLICY)}>
          Policy
        </Link>
        <Link style={{ ...TextStyle }} href="" onClick={() => navigate(ROUTE_PATHS.TERMS_CONDITIONS)}>
          Terms & Conditions
        </Link>
      </HStack>
      <HStack marginTop={3}>
        <IconButton
          colorScheme="undefined"
          aria-label="Github"
          size="lg"
          icon={
            <BsGithub
              style={{
                color: 'gray',
                fontSize: 25,
              }}
            />
          }
          onClick={() => onClick('https://github.com/xegai-hadgu', 'Github')}
        />
        <IconButton
          colorScheme="undefined"
          aria-label="Linkedin"
          size="lg"
          icon={
            <BsLinkedin
              style={{
                color: 'gray',
                fontSize: 25,
              }}
            />
          }
          onClick={() => onClick('https://www.linkedin.com/in/xegai-hadgu/', 'LinkedIn')}
        />
        <IconButton
          colorScheme="undefined"
          aria-label="Twitter"
          size="lg"
          icon={
            <BsTwitter
              style={{
                color: 'gray',
                fontSize: 25,
              }}
            />
          }
          onClick={() => {}}
        />
      </HStack>

      <Text style={{ fontSize: 12, fontFamily: 'Roboto', color: '#b3b3b3', fontWeight: '500' }}>
        Copyright &#169; {new Date().getFullYear()} Xegai Hadgu
      </Text>
    </VStack>
  );
};

export default FooterContainer;
