import { Avatar, Box, HStack, IconButton, Link, Text, VStack } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { BsGithub, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { ROUTE_PATHS } from '../../App';
import { FULL_HEIGHT } from '../../global-constants/sizes';
import { BLOG_CARD_WIDTH } from '../common/BlogCard';
import ReactGA from 'react-ga4';

const textStyle = {
  fontSize: '18px',
  fontFamily: 'Roboto',
  color: 'gray',
};
const AboutContainer = () => {
  const onClick = (link: string, category: string) => {
    ReactGA.event({
      category: category,
      action: 'Click',
      label: 'platform',
    });

    window.open(link, '_blank');
  };
  return (
    <HStack w={BLOG_CARD_WIDTH * 2 + 30} minH={FULL_HEIGHT} margin={50} display="flex" alignItems="flex-start" justifyContent="flex-start">
      <VStack w="70%" display="flex" alignItems="flex-start" justifyContent="flex-start" paddingRight={10}>
        <HStack textAlign="start">
          <Text fontSize="22px" fontFamily="Roboto" fontWeight="bold" color="#4d4d4d">
            Hello there! I'm Xegai Hadgu
          </Text>
          <Player style={{ width: 40, height: 40 }} loop autoplay src={require('../../assets/animations/hi.json')} />
        </HStack>
        <Text style={{ ...textStyle }}>
          I have a degree in Computer Science and Mathematics, and I've been programming for the past {new Date().getFullYear() - 2019}{' '}
          years. I enjoy finding new ways to save time by automating tasks, solving problems, and writing neat, reusable code.
        </Text>
        <Text style={{ ...textStyle }}>
          I've worked on several pesonal project, such as fully responsive and interactive websites, and native and hybrid mobile apps. Some
          of my side projects can be found{' '}
          <Link href={`./${ROUTE_PATHS.SIDE_PROJECTS}`} target="_blank" color="#378CE7">
            {' '}
            here{' '}
          </Link>
          .
        </Text>
        <Text style={{ ...textStyle }}>
          If I am not programming, which most of the time I am, I am either playing soccer, hanging out with friends or hiking.
        </Text>

        <Text style={{ ...textStyle }}>
          My motive for creating this blog is that because I beleive software engineering is one of the cereers that requirs constant
          learning and catching up with new technologies, which includes learning from other fellow engineers as well. When I am working ,
          whether on personal project or my actaul work, I come across several interseting new techonolgies, new way of implementing things,
          bugs etc. So, I wanted to document it to share my knowledge with others.
        </Text>
      </VStack>
      <Box w="30%">
        <VStack
          w={350}
          height={350}
          borderRadius={20}
          padding={10}
          backgroundColor="#333333"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Avatar size="2xl" name="Xegai Hadgu" src={require('../../assets/profile.JPG')} />
          <Text fontSize="20px" fontFamily="Roboto" textAlign="center" fontWeight="bold" color="#cccccc">
            Xegai Hadgu
          </Text>
          <Text fontSize="18px" fontFamily="Roboto" textAlign="center" fontWeight="500" color="#378CE7">
            FullStack Developer
          </Text>
          <HStack marginTop={5}>
            <IconButton
              colorScheme="undefined"
              aria-label="Github"
              size="lg"
              icon={
                <BsGithub
                  style={{
                    color: 'gray',
                    fontSize: 25,
                  }}
                />
              }
              onClick={() => onClick('https://github.com/xegai-hadgu', 'Github')}
            />
            <IconButton
              colorScheme="undefined"
              aria-label="Linkedin"
              size="lg"
              icon={
                <BsLinkedin
                  style={{
                    color: 'gray',
                    fontSize: 25,
                  }}
                />
              }
              onClick={() => onClick('https://www.linkedin.com/in/xegai-hadgu/', 'LinkedIn')}
            />
            <IconButton
              colorScheme="undefined"
              aria-label="Twitter"
              size="lg"
              icon={
                <BsTwitter
                  style={{
                    color: 'gray',
                    fontSize: 25,
                  }}
                />
              }
              onClick={() => {}}
            />
          </HStack>
        </VStack>
      </Box>
    </HStack>
  );
};

export default AboutContainer;
