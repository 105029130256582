import { Box, Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { FULL_HEIGHT } from '../../global-constants/sizes';
import { formatDate } from '../../utils/utils';

const headerStyle = {
  color: '#666666',
  fontSize: '25px',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  marginTop: '30px',
};

const titleStyle = {
  ...headerStyle,
  color: '#333333',
  fontSize: '30px',
};
const textStyle = {
  fontSize: '18px',
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  color: '#999999',
};
function PolicyMobileContainer() {
  return (
    <Box w="100%" minH={FULL_HEIGHT} display="flex" alignItems="flex-start" justifyContent="center">
      <VStack w="70%" h="100%" p={20} display="flex" alignItems="flex-start" justifyContent="flex-start">
        <Text style={{ ...titleStyle }}>Privacy Policy</Text>
        <Text style={{ ...textStyle }}>
          Thanks for using our application! We are committed to protecting your privacy. This Privacy Policy describes how we collect, use,
          and disclose your information when you using our app.
        </Text>
        <Text style={{ ...headerStyle }}>Information Collection and Use</Text>
        <Text style={{ ...textStyle }}>
          When you're using the app, we may collect certain information automatically, including your IP address operating system, and other
          usage information. We may also collect information about your interactions on the app, such as the pages or content you view, the
          features you use, and the actions you take.
        </Text>

        <Text style={{ ...headerStyle }}>Use of Information</Text>
        <Text style={{ ...textStyle }}>We may use the information we collect for various purposes, including but not limited to:</Text>
        <UnorderedList style={{ ...textStyle }}>
          <ListItem>Your IP address</ListItem>
          <ListItem>Operating system type</ListItem>
          <ListItem>Device type</ListItem>
        </UnorderedList>
        <Text style={{ ...textStyle }}>This information is utilized to:</Text>
        <UnorderedList style={{ ...textStyle }}>
          <ListItem>Personalize your experience on the app</ListItem>
          <ListItem>Provide, maintain, and improve the app</ListItem>
          <ListItem>Analyze usage and trends</ListItem>
          <ListItem>Communicate with you about the app and our services</ListItem>
          <ListItem>Comply with legal obligations</ListItem>
        </UnorderedList>
        <Text style={{ ...headerStyle }}>Third-Party Analytics</Text>
        <Text style={{ ...textStyle }}>
          We may use third-party analytics services, such as Google Analytics, to help us understand how visitors use the app and to improve
          its performance. These analytics services may collect information about interaction on the app. You can learn more about Google
          Analytics and how it collects and processes data by visiting
          <Link style={{ color: '#73afee' }} href="https://policies.google.com/technologies/partner-sites">
            {' '}
            https://policies.google.com/technologies/partner-sites
          </Link>
          .
        </Text>
        <Text style={{ ...headerStyle }}>Disclosure of Information</Text>
        <Text style={{ ...textStyle }}>We may share your information with third parties as follows:</Text>
        <UnorderedList style={{ ...textStyle }}>
          <ListItem>With service providers who help us operate the App and provide our services</ListItem>
          <ListItem>With analytics and advertising partners for the purposes described in this Privacy Policy</ListItem>
          <ListItem>
            In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from law enforcement or
            other government agency
          </ListItem>
          <ListItem>
            If we believe disclosure is necessary or appropriate to protect our rights, property, or safety, or that of our users or others
          </ListItem>
        </UnorderedList>
        <Text style={{ ...headerStyle }}>Children's Privacy</Text>
        <Text style={{ ...textStyle }}>
          The App is not directed to individuals under the age of 13, and we do not knowingly collect personal information from children
          under 13. If you believe that a child under 13 may have provided us with personal information, please contact us at
          <Link color="#73afee" href="mailto:gomche02@gmail.com">
            {' '}
            gomche02@gmail.com
          </Link>
          , and we will take appropriate steps to delete such information.
        </Text>
        <Text style={{ ...headerStyle }}>Changes to this Privacy Policy</Text>
        <Text style={{ ...textStyle }}>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you
          of any material changes by posting the updated Privacy Policy on the App. Your continued use of the app after the effective date
          of the updated Privacy Policy constitutes your consent to the changes.
        </Text>
        <Text style={{ ...headerStyle }}>Contact Us</Text>
        <Text style={{ ...textStyle }}>
          If you have any questions or concerns about this Privacy Policy or our practices regarding your information, please contact us at
          <Link color="#73afee" href="mailto:gomche02@gmail.com">
            {' '}
            gomche02@gmail.com
          </Link>
        </Text>
        <Text style={{ fontSize: 8, fontFamily: 'Roboto', color: 'gray', marginTop: 40 }}>
          This Privacy Policy was last updated on {formatDate('03/10/2024')}
        </Text>
      </VStack>
    </Box>
  );
}

export default PolicyMobileContainer;
