import { Box, Image, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BLOG, BLOGS } from '../../blogs/blogs';
import { FULL_HEIGHT } from '../../global-constants/sizes';
import { formatDate } from '../../utils/utils';
import PageNotFound from '../PageNotFound';
import { BLOG_CARD_WIDTH } from '../common/BlogCard';
import TerminalJava from './java/Terminal';
import TerminalJs from './js/Terminal';

const BlogList = () => {
  const { state } = useLocation();
  const [blog, setBlog] = useState<BLOG | undefined>(undefined);

  const ClickedBlog = useCallback(() => {
    switch (state?.id) {
      case 0:
        return <TerminalJava />;
      case 1:
        return <TerminalJs />;
      default:
        return <PageNotFound />;
    }
  }, [state]);

  useEffect(() => {
    if (state && state?.id !== undefined) {
      const filter = BLOGS.find(b => b.id === state.id);
      setBlog(filter);
    }
  }, [state, state.id]);

  return (
    <VStack w="100%" minH={FULL_HEIGHT} maxW={BLOG_CARD_WIDTH * 2 + 30}>
      <PageHeader image={blog?.imagePath ?? ''} date={blog?.lastUpdate ?? ''} title={blog?.title ?? ''} />
      {ClickedBlog()}
    </VStack>
  );
};

export default BlogList;

interface PageHeaderProps {
  image: string;
  title: string;
  date: string;
}
const PageHeader = ({ image, date, title }: PageHeaderProps) => {
  return (
    <VStack w="100%" mt={20} style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', height: 400 }}>
        {/* <Image sx={{ width: '100%', height: '100%' }} objectFit="cover" src={require(image)} alt="blog pic" /> */}
        <Image sx={{ width: '100%', height: '100%' }} objectFit="cover" src={image} alt="blog pic" />
      </Box>
      <Text style={{ fontSize: 12, color: 'gray', paddingLeft: 5 }}>Last updated: {formatDate(date)}</Text>
      <Text style={{ fontSize: 40, color: 'black', fontFamily: 'Roboto', fontWeight: 'bold', paddingTop: 10 }}>{title}</Text>
    </VStack>
  );
};

interface ImageContainerProps {
  width?: number;
  height?: number;
  imagePath: string;
}
export const ImageContainer = ({ width, height, imagePath }: ImageContainerProps) => {
  return (
    <Box sx={{ w: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Image sx={{ width: width ?? '100%', height: height ?? '100%' }} objectFit="cover" src={imagePath} alt="blog pic" />
    </Box>
  );
};
