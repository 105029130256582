import { Box, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { PROJECT } from '../../blogs/projects';

export const PROJECT_CARD_WIDTH = 350;
export const PROJECT_CARD_HEGHT = 180;
interface BlogCardProps {
  project: PROJECT;
  onClick: () => void;
}
const ProjectCard = ({ project, onClick }: BlogCardProps) => {
  return (
    <>
      <Box w={PROJECT_CARD_WIDTH} h={PROJECT_CARD_HEGHT} as="button" onClick={onClick}>
        <Box
          as={motion.div}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition="0.5s linear type: Tween stiffness: 100"
          boxShadow="sm"
          rounded="xl"
          overflow="hidden"
          bg="gray.50"
        >
          <Image sx={{ width: '100%', height: '100%' }} objectFit="cover" src={project.imagePath} alt="blog pic" _hover={{ bg: 'red' }} />
        </Box>
      </Box>
    </>
  );
};

export default ProjectCard;
