import { VStack } from '@chakra-ui/react';
import { FULL_HEIGHT, FULL_WIDTH } from '../../global-constants/sizes';

interface ContainerProps {
  children: JSX.Element | JSX.Element[];
}
const AppContainer = ({ children }: ContainerProps) => {
  return (
    <VStack
      style={{
        width: FULL_WIDTH,
        minHeight: FULL_HEIGHT,
        display: 'flex',
      }}
    >
      {children}
    </VStack>
  );
};

export default AppContainer;
