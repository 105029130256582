import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppHeader from './components/Header';
import PageNotFound from './components/PageNotFound';
import AboutContainer from './components/about/AboutConainer';
import { BlogDetailsContainer } from './components/blogs/BlogDetailsContainer';
import BlogList from './components/blogs/BlogList';
import AppContainer from './components/common/AppContainer';
import ContactsContainer from './components/contacts/ContactsContainer';
import Footer from './components/footer/FooterContainer';
import HomeContainer from './components/home/HomeContainer';
import PolicyContainer from './components/policy/PolicyContainer';
import PolicyMobileContainer from './components/policy/PolicyMobileContainer';
import ProjectsContainer from './components/projects/PorjectsContainer';
import TermsContainer from './components/terms-conditions/TermsContainer';
import TermsMobileContainer from './components/terms-conditions/TermsMobileContainer';
import { Alert, AlertIcon } from '@chakra-ui/react';

export enum ROUTE_PATHS {
  HOME = 'home',
  ABOUT = 'about',
  CONTACTS = 'contacts',
  POLICY = 'policy',
  TERMS_CONDITIONS = 'terms-and-conditions',
  BLOGS = 'blogs',
  SIDE_PROJECTS = 'side-projects',
}
const App = () => {
  const { pathname } = useLocation();

  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: pathname, title: pathname });
  }, [pathname]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <AppContainer>
      {/* If on Mobile */}
      {width <= 768 ? (
        <Alert status="info">
          <AlertIcon />
          For a better view, use a wider screen like a laptop since the website isn't responsive.
        </Alert>
      ) : (
        <></>
      )}
      {pathname !== `/${ROUTE_PATHS.SIDE_PROJECTS}` ? <AppHeader /> : <></>}
      <Routes>
        <Route index element={<HomeContainer />} />
        <Route path={ROUTE_PATHS.BLOGS} element={<HomeContainer />} />
        <Route
          path={`${ROUTE_PATHS.BLOGS}/:title`}
          element={
            <BlogDetailsContainer>
              <BlogList />
            </BlogDetailsContainer>
          }
        />
        <Route path={ROUTE_PATHS.ABOUT} element={<AboutContainer />} />
        <Route path={ROUTE_PATHS.CONTACTS} element={<ContactsContainer />} />
        <Route path={ROUTE_PATHS.POLICY} element={<PolicyContainer />} />
        <Route path={`${ROUTE_PATHS.POLICY}/mobile`} element={<PolicyMobileContainer />} />
        <Route path={ROUTE_PATHS.TERMS_CONDITIONS} element={<TermsContainer />} />
        <Route path={`${ROUTE_PATHS.TERMS_CONDITIONS}/mobile`} element={<TermsMobileContainer />} />
        <Route path={ROUTE_PATHS.SIDE_PROJECTS} element={<ProjectsContainer />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </AppContainer>
  );
};

export default App;
