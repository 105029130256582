import { Box, Text } from '@chakra-ui/react';
import { TextStyle } from '../../common/styles';
import { CodeContainer } from '../../common/CodeContainer';
import { ImageContainer } from '../BlogList';

function Terminal() {
  return (
    <Box w="100%" h="100%">
      <Text style={{ ...TextStyle }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived
        not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
        1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
        Aldus PageMaker including versions of Lorem Ipsum.
      </Text>
      <CodeContainer code={CODES[0]} language="JavaScript" />
      <ImageContainer height={600} width={500} imagePath={require('../../../assets/image.png')} />
    </Box>
  );
}

export default Terminal;

export const CODES = [
  `const nums = [5, 2, 6, 3, 1, 7, 4]; \n\n//❌ Have to clone to prevent mutation \nconst clone = [...nums]; \nconst sorted = clone.sort()`,
];
