import { Box } from '@chakra-ui/react';
import { CopyBlock, dracula } from 'react-code-blocks';

interface CodeContainerProps {
  code: string;
  language: string;
  showLineNumbers?: boolean;
}
export const CodeContainer = ({ code, language, showLineNumbers }: CodeContainerProps) => {
  return (
    <Box
      style={{
        padding: 10,
        width: '100%',
      }}
    >
      <CopyBlock text={code} language={language} showLineNumbers={showLineNumbers ?? false} wrapLongLines codeBlock theme={dracula} />
    </Box>
  );
};
