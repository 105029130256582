export enum LANG_TYPE {
  REACT = 'React',
  TYPESCRIPT = 'Typescript',
  JAVASCRIPT = 'Javascript',
  JAVA = 'Java',
  CPP = 'C++',
  Python = 'Python',
  SPRINT_BOOT = 'Spring Boot',
}

export enum PROJECT_TYPE {
  WEBSITE = 'website',
  MOBILE = 'mobile',
}

export interface PROJECT {
  id: number;
  title: string;
  type: PROJECT_TYPE;
  imagePath: string;
  description: string[];
  techStacks: string[]; //technalogies used
  githubLink: string;
  websiteLink: string | undefined;
  appstoreLink: string | undefined;
}

export const PROJECTS: PROJECT[] = [
  {
    id: 1,
    title: 'US Citzeship',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/citizen.png'),
    description: [
      'US Citnzeship is a React Native mobile app that helps you prepare for the US citizenship test or learn about the US history.',
      'The app contains 100 question in various formats, such as, mulitple choices, flash cards, writing, reading etc. ',
    ],
    techStacks: ['React Native', 'Typescript', 'Google API', 'Firebase'],
    githubLink: 'https://github.com/xegai-hadgu/USCitizenshipTest',
    websiteLink: undefined,
    appstoreLink: 'https://apps.apple.com/us/app/us-citizenship/id1602939403',
  },
  {
    id: 2,
    title: 'Gezana',
    type: PROJECT_TYPE.WEBSITE,
    imagePath: require('../assets/projects/gezana.png'),
    description: [
      'Gezana which means "our house" in Tigrigna is, a Tigrayan community-based platform to that lets you find nearby Tigrayan-owned services, such as restaurants, hotels, religious centers etc',
      'The platform currently has over 100 users per day and has over 50 registered services.',
    ],
    techStacks: ['React', 'Typescript', 'Chakra UI', 'Digital Ocean VM', 'MySQL'],
    githubLink: 'https://github.com/xegai-hadgu/Gezana-web',
    websiteLink: 'https://gezana.io/',
    appstoreLink: undefined,
  },
  {
    id: 3,
    title: 'Get Done',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/todo.png'),
    description: ['A light weight and intuitive to-do list app that helps you stay organized.'],
    techStacks: ['React Native', 'Typescript', 'AsyncStorage'],
    githubLink: 'https://github.com/xegai-hadgu/GetDone',
    websiteLink: undefined,
    appstoreLink: 'https://apps.apple.com/us/app/get-done/id1641388050',
  },
  {
    id: 4,
    title: 'TSFNA',
    type: PROJECT_TYPE.WEBSITE,
    imagePath: require('../assets/projects/tsfna.png'),
    description: [''],
    techStacks: ['React', 'Typescript', 'MUI'],
    githubLink: 'https://github.com/xegai-hadgu/tsfna-official',
    websiteLink: 'https://tsfna.org/',
    appstoreLink: undefined,
  },
  {
    id: 5,
    title: 'WedhiHadgu Blog',
    type: PROJECT_TYPE.WEBSITE,
    imagePath: require('../assets/projects/tsfna.png'),
    description: [
      'WediHadgu is my personal blog where I share insights, experiences, and knowledge gained from my day-to-day work as a software engineer.',
    ],
    techStacks: ['React', 'Typescript', 'Chakra UI', 'Framer-motion'],
    githubLink: 'https://github.com/xegai-hadgu/codexpress',
    websiteLink: 'https://wedihadgu.com/',
    appstoreLink: undefined,
  },
  {
    id: 6,
    title: 'Safe Locker',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/safe-locker.png'),
    description: [
      'Hide all your photos and passwords in one safe and secure place. Safe-locker gives you complete control of your files and passwords. It allows you to easily upload, access and share your files',
    ],
    techStacks: ['React Native', 'Typescript', 'SQLite'],
    githubLink: 'https://github.com/xegai-hadgu/safeLocker-2',
    websiteLink: undefined,
    appstoreLink: 'https://apps.apple.com/us/app/safe-locker/id1609526884',
  },
  {
    id: 7,
    title: 'Smart Calculator',
    type: PROJECT_TYPE.MOBILE,
    imagePath: require('../assets/projects/calc.png'),
    description: ['An all in one smart calculator. The app contains, basic and Advanced calculator, Geometries and other cool features.'],
    techStacks: ['React Native', 'Typescript', 'SQLite'],
    githubLink: 'https://github.com/xegai-hadgu/SmartCalculator',
    websiteLink: undefined,
    appstoreLink: 'https://apps.apple.com/us/app/smart-calc/id1608259008',
  },
];
