import { Box } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { FULL_HEIGHT } from '../global-constants/sizes';

const PageNotFound = () => {
  return (
    <Box w="100%" h={FULL_HEIGHT} display="flex" alignItems="flex-start" justifyContent="center">
      <Player style={{ width: 800, height: 800 }} loop autoplay src={require('../assets/animations/404.json')} />
    </Box>
  );
};

export default PageNotFound;
