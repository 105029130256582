import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

//icons
import { BsApple, BsGithub } from 'react-icons/bs';
import { TbWorld } from 'react-icons/tb';
import { PROJECT, PROJECTS, PROJECT_TYPE } from '../../blogs/projects';
import ProejectCard from './ProjectCard';
const ProjectsContainer = () => {
  const [filter, setfilter] = useState('all');
  const [Modal, setModal] = useState(false);
  const [clickedModal, setClickedModal] = useState<PROJECT | undefined>(undefined);
  const [projects, setProjects] = useState(PROJECTS);

  useEffect(() => {
    if (filter === 'mobile') {
      const filtered = PROJECTS.filter(p => p.type === PROJECT_TYPE.MOBILE);
      setProjects(filtered);
    } else if (filter === 'websites') {
      const filtered = PROJECTS.filter(p => p.type === PROJECT_TYPE.WEBSITE);
      setProjects(filtered);
    } else {
      setProjects(PROJECTS);
    }
  }, [filter]);

  return (
    <VStack w="100%" minHeight="100%" display="flex" alignItems="center" justifyContent="center">
      <VStack w="100%" height={150} bg="#e6e6e6" display="flex" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={4}>
          <Button colorScheme="teal" variant={filter === 'all' ? 'solid' : 'outline'} onClick={() => setfilter('all')}>
            All
          </Button>
          <Button
            leftIcon={<TbWorld />}
            colorScheme="teal"
            variant={filter === 'websites' ? 'solid' : 'outline'}
            onClick={() => setfilter('websites')}
          >
            Websites
          </Button>
          <Button
            leftIcon={<BsApple />}
            colorScheme="teal"
            variant={filter === 'mobile' ? 'solid' : 'outline'}
            onClick={() => setfilter('mobile')}
          >
            Mobile apps
          </Button>
        </Stack>
        <Text fontSize="8px" color="gray">
          Feel free to contact me if you have any questions about any of the projects.
        </Text>
      </VStack>

      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="8" p="10" textAlign="center" rounded="lg" color="gray.400">
        {projects.map((proj, index) => (
          <ProejectCard
            key={index}
            project={proj}
            onClick={() => {
              setModal(true);
              setClickedModal(proj);
            }}
          />
        ))}
      </SimpleGrid>
      {clickedModal && <ProjectDetails isOpen={Modal} onClick={setModal} project={clickedModal} />}
    </VStack>
  );
};

export default ProjectsContainer;

interface ProjectDetailsProps {
  isOpen: boolean;
  onClick: (open: boolean) => void;
  project: PROJECT;
}
export function ProjectDetails({ isOpen, onClick, project }: ProjectDetailsProps) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => onClick(false)} isCentered blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent maxH="800px" maxW="800px">
          <ModalHeader style={{ fontSize: 30, fontFamily: 'Roboto' }}>{project.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w="100%" height="100%" display="flex" alignItems="flex-start" justifyContent="center">
              <VStack w="60%" height="100%">
                <Box w="100%" height="auto">
                  {project.description.map(desc => (
                    <>
                      <Text textAlign="start" fontSize={16} fontFamily="Roboto" color="gray">
                        {desc}
                      </Text>
                      <Text mt={3} />
                    </>
                  ))}
                </Box>
                <SimpleGrid columns={{ md: 4 }} spacing="2" mt={20}>
                  {project.techStacks.map((v, index) => (
                    <TechStack title={v} key={index} />
                  ))}
                </SimpleGrid>
              </VStack>
              <VStack w="40%" height="100%" bg="green" top={0}>
                <Image
                  sx={{ width: '100%', height: '100%' }}
                  objectFit="cover"
                  src={project.imagePath}
                  alt="blog pic"
                  _hover={{ bg: 'red' }}
                />
              </VStack>
            </HStack>
          </ModalBody>

          <ModalFooter width="100%" display="flex" alignItems="center" justifyContent="center">
            <Stack direction="row" spacing={4}>
              {project.websiteLink && (
                <Button
                  leftIcon={<TbWorld />}
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => window.open(project.websiteLink, '_blank')}
                >
                  Website
                </Button>
              )}
              {project.appstoreLink && (
                <Button
                  leftIcon={<BsApple />}
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => window.open(project.appstoreLink, '_blank')}
                >
                  App Store
                </Button>
              )}
              <Button leftIcon={<BsGithub />} colorScheme="teal" variant="solid" onClick={() => window.open(project.githubLink, '_blank')}>
                Github
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

interface TechStackProps {
  title: string;
}
export const TechStack = ({ title }: TechStackProps) => {
  return (
    <Box
      minW={100}
      h={5}
      borderWidth={1}
      borderColor="#008080"
      borderRadius={15}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize={10}>{title}</Text>
    </Box>
  );
};
