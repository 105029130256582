import { Box } from '@chakra-ui/react';
import React from 'react';

export interface BlogDetailsContainerProps {
  children: React.ReactNode;
}

export const BlogDetailsContainer = ({ children }: BlogDetailsContainerProps) => {
  return <Box sx={{ width: '55%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{children}</Box>;
};
