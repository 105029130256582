import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { FULL_HEIGHT } from '../../global-constants/sizes';

const textStyle = {
  fontSize: '22px',
  fontFamily: 'Roboto',
  color: 'gray',
};
const CotnactsContainer = () => {
  return (
    <VStack w="100%" height={FULL_HEIGHT} marginTop={100}>
      <HStack>
        <Box w="60%" height={400} padding={20}>
          <Text style={{ ...textStyle }}>
            If you have any inquiries, suggestions, or feedback, please reach out to me at {'\n'}
            <Link fontWeight="bold" color="#378CE7" href="mailto:gomche02@gmail.com">
              gomche02@gmail.com
            </Link>
            {'.  '}
            Alterntively, you can shoot me a message on{' '}
            <Link fontWeight="bold" color="#378CE7" href="mailto:gomche02@gmail.com">
              Linkedin
            </Link>
            {' as well. '}
          </Text>
        </Box>
        <Box w="40%" height={400}>
          <Player style={{ width: 400, height: 400 }} loop autoplay src={require('../../assets/animations/contacts.json')} />
        </Box>
      </HStack>
    </VStack>
  );
};

export default CotnactsContainer;
